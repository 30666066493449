import React from 'react';
import styled from 'styled-components';
import { MAIN_COLOR } from '../constants';

const StyledButton = styled.button`
	width: auto;
	height: auto;
	background-color: ${props => props.bgColor ? props.bgColor : MAIN_COLOR};
	color: white;
	padding: ${props => props.small ? '4px 8px' : '12px 24px'};
	font-size: ${props => props.small ? '12px' : '16px'};
	border: none;
	border-radius: 8px;
	text-align: center;
	outline: none;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
`;

export default function RoundedButton({ bgColor, children, click, small }) {
  return (
    <StyledButton small={small} onClick={click} bgColor={bgColor}>
      { children }
    </StyledButton>
  )
}
