export const getPriceMultiplier = (source, isManuallyAdded, categories) => {
  if (isManuallyAdded) {
    return 1;
  }

  if (source === "stricker") { return 1.8; }
  if (source === "mob" && (categories?.includes(1831) || categories?.includes(1829))) { return 1.8; }
  if (source === "mob") { return 1.8; }
  if (source === "anda") { return 1.8; }
  if (source === "pf") { return 1.8; }

  return 1.3;
};

export function getProductPriceClass({ activeVariation = {}, quantity }) {
  let variationQuantityRules = [];

  if (activeVariation.prices && typeof activeVariation.prices === 'string') {
    variationQuantityRules = JSON.parse(activeVariation.prices) || [];
  }
  else if (activeVariation.prices) {
    variationQuantityRules = activeVariation.prices || [];
  }

  const quantityRanges = variationQuantityRules
    .map((r) => parseFloat(r.quantity, 10))
    .sort((a, b) => a - b);

  const qtyVariation = quantityRanges.reduce((acc, current) => {
    return quantity >= current ? current : acc;
  }, 0);

  const priceVariation = variationQuantityRules.find(
    (r) => parseFloat(r.quantity, 10) === qtyVariation
  );

  return {
    smallest: Math.min(
      ...variationQuantityRules
        .filter((r) => !!r.price)
        .map((r) => parseFloat(r.price))
    ),
    priceClass: priceVariation && parseFloat(priceVariation.price).toFixed(3),
  };
}

export function getProductPriceData({ variations, source, manual_product, categories }) {
  const productPriceClass = getProductPriceClass({
    activeVariation: (variations && variations[0]) || {},
    quantity: 100,
  });

  return {
    priceClass: productPriceClass.smallest,
    multiplier: getPriceMultiplier(source, manual_product, categories),
  };
}
