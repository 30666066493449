const getCategoryWithChildData = function (
  category,
  parentSlug = null,
  flatCollection
) {
  let flatCopy = [...flatCollection];

  const categoryData = {
    ...category,
    parentSlug: parentSlug,
  };

  if (!category?.children?.length) {
    return [...flatCopy, categoryData];
  }

  categoryData.children.forEach((child) => {
    flatCopy = getCategoryWithChildData(child, category.slug, flatCopy);
  });
  categoryData.children = categoryData.children.map((c) => c.slug);

  flatCopy = [...flatCopy, categoryData];

  return [...flatCopy];
  // If has children
  // Go to children -> get slugs -> return

  // If has not children
  // Set parent slug
};

export function getFlatCategories(categories) {
  return categories
    .reduce((acc, curr) => {
      return [...getCategoryWithChildData(curr, null, acc)];
    }, [])
    .reduce((acc, curr) => {
      if (acc.find((c) => c.slug === curr.slug)) {
        return acc;
      }

      return [...acc, curr];
    }, []);
}

export function getActiveCategoryObject(categories, activeCategorySlug) {
  let category = {};
  categories.forEach((currentCat) => {
    if (currentCat.slug === activeCategorySlug) {
      category = { ...currentCat };
      return;
    }

    const hasChildren = currentCat.children && currentCat.children.length;

    let foundChild =
      hasChildren &&
      currentCat.children.find((c) => c.slug === activeCategorySlug);
    let currentCatSlug = currentCat.slug;

    if (hasChildren && !foundChild) {
      const childWithSubChildren = currentCat.children.find((c) => c.children);
      const subChildren = childWithSubChildren?.children.find(
        (c) => c.slug === activeCategorySlug
      );

      currentCatSlug = subChildren ? childWithSubChildren.slug : currentCatSlug;
      foundChild = subChildren;
    }
    if (foundChild) {
      category = { ...foundChild, parentSlug: currentCatSlug };
      return;
    }
  });
  return category;
}

export function getVisibleCategories(categories, activeCategory) {
  if (!activeCategory || !activeCategory.slug) {
    return [...categories];
  }

  // Top level without parents or children
  if (
    activeCategory.slug &&
    !activeCategory.children &&
    !activeCategory.parentSlug
  ) {
    return [...categories];
  }

  if (activeCategory.children && activeCategory.children.length) {
    return [...activeCategory.children];
  }

  if (activeCategory.parentSlug) {
    let theCategories = categories.find((c) => {
      return activeCategory.parentSlug === c.slug;
    })?.children;

    // Try subsub categories
    if (!theCategories) {
      const categoryWithSubChildren = categories.reduce((acc, current) => {
        if (acc) {
          return acc;
        }

        const subChildren =
          current.children?.length &&
          current.children.find((cc) => cc.children?.length);
        return subChildren?.children?.length ? subChildren : null;
      }, null);

      theCategories = categoryWithSubChildren?.children;
    }

    return (theCategories && [...theCategories]) || [];
  }

  return [];
}

export function getShowAllCategory(category, allCategories) {
  if (!category || !category.slug) {
    return null;
  }

  if (category.children && category.children.length) {
    return { ...category };
  }

  if (category.parentSlug) {
    return allCategories.find((c) => category.parentSlug === c.slug);
  }

  return null;
}

export function getProductPrice({
  smallestPrice,
  priceMultiplier,
  priceOnRequestString,
  isManual = true,
}) {
  const FIXED_COUNT = isManual ? 3 : 2;
  return Math.round(smallestPrice * priceMultiplier * 100) / 100 === Infinity
    ? priceOnRequestString
    : parseFloat(smallestPrice * priceMultiplier).toFixed(FIXED_COUNT);
}
